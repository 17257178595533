.error {
  order: 1;
  font-size: 13px;
  color: #f53242;
  .error-message {
    padding: 0 2px;
    text-align: center;
  }
  &.error-date {
    font-size: 13px;
    padding: 3px 5px 0;
  }
  + .field {
    input {
      border-color: #f53242;
      color: #f53242;
      background-color: rgba(#f53242, .1);
    }
    .v-select {
      .vs__dropdown-toggle {
        border-color: #f53242;
        background-color: rgba(#f53242, .1);
      }
    }
    .mx-datepicker .mx-input-wrapper .mx-input {
      border-color: #f53242;
      background-color: rgba(#f53242, .1);
    }
  }
}

.inline {
  .error-date {
    @extend %flexbox;
    @include align-items(center);
    height: 100%;
  }
}

.question-wrapper .question-wrapper {
  .justify-content-center {
    .error {
      .error-message {
        text-align: center;
      }
    }
  }
  .error {
    .error-message {
      text-align: left;
    }
  }
}
.questions-inline.field {
  .question-wrapper .question-wrapper {
    .error {
      .error-message {
        text-align: right;
        padding-top: 0;
        margin-top: -4px;
      }
    }
  }
}

$icon-confirmation-width: 100px;
$icon-confirmation-height: 80px;

i.icon-confirmation {
  display: inline-block;
  width: $icon-confirmation-width;
  height: $icon-confirmation-height;
  background-image: url("../images/sprite-confirmation.png");
  &.check { background-position: #{$icon-confirmation-width * 0} #{$icon-confirmation-height * 0}; }
  &.wait { background-position: #{$icon-confirmation-width * -1} #{$icon-confirmation-height * 0}; }
}

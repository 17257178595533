// Steps
$mobile-steps-padding: 0;
$mobile-steps-margin: 0;
$steps-padding: 0 60px 50px;
$steps-margin: 0 0 20px;


// Step
  $step-text-size: 14px;
  $step-text-color: #585858;
  $step-text-weight: normal;
  $step-text-align: center;
  $isStepTextWidthConstrained: 'yes'; // yes or no if yes => width else text nowrap
    $step-text-width: 90px;
  $step-bg-color: #cdcdcd;
  $mobile-step-padding-bottom: 8px;

  // Step Icon Block
    $step-icon-block-width: 36px;
    $step-icon-block-height: $step-icon-block-width;
    $step-icon-block-radius: 100%;
    // Step Icon
      $isIconStyle: 'number'; // sprite, number, fonticon
      // if is Number or fonticon
        $step-icon-size: 13px;
        $step-icon-style: normal;
        $step-icon-color: #444444;
      // if is Sprite
        $step-icon-url: url("../images/sprite-nav.png");

// Active Step
  $step-active-text-color: $quaternary;
  $step-active-text-weight: 600;
  $step-active-icon-color: #fff;
  $step-active-line-bg-color: $step-bg-color;
  $step-active-bg-color: $tertiary;


// Achieve Step
  $step-achieve-text-color: #444444;
  $step-achieve-text-weight: 600;
  $step-achieve-icon-color: #fff;
  $step-achieve-line-bg-color: $step-bg-color;
  $step-achieve-bg-color: $quaternary;




* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f4f0;
}

main.container {
  padding: 20px;
  background-color: #fff;
}

img {
  max-width: 100%;
}

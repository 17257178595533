.calcul {
  background-color: #f5f4f0;
  > .question-title {
    color: $quaternary;
    text-transform: uppercase;
    padding-top: 14px;
  }
  .question-wrapper {
    margin-bottom: 0 !important;
  }
  .field, .field.field  {
    @extend %flexbox;
    @include flex-direction(row);
    color: $quaternary;
    line-height: 1;
    margin-bottom: 40px;
  }
  .montant-mois {
    font-size: 90px;
  }
  .montant-details {
    @extend %flexbox;
    @include flex-direction(column);
    font-size: 21px;
    padding-top: 10px;
    margin-left: 10px;
    .montant-details-mois {
      font-size: 24px;
    }
  }

}

.dp__main{
  
  * {
    font-size: 14px !important;
  }
}

.dp__input{
  background-color: #f9f9f9 !important;
}

.error + .field .dp__input{
  background-color: rgba(245, 50, 66, 0.1) !important;
}
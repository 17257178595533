.storeData {
  margin-bottom: 0;
  + .field.links {
    margin-top: 20px;
  }
  .storeDatas {
    width: 100%;
  }
  .storeDataTitle {
    color: #3c8d7b;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 14px;
  }
  .storeDataBlock {
    background-color: #f9f9f9;
    padding: 14px 24px;
    margin-left: 30px;
    margin-bottom: 10px;
    span {
      font-weight: 600;
    }
  }
  .storeDataNav {
    @extend %flexbox;
    @include justify-content(center);
    a {
      @extend %flexbox;
      @include align-items(center);
      color: #000;
      font-size: 14px;
      text-decoration: underline;
      &:hover {
        text-decoration: underline;
      }
      &:first-child {
        &:after {
          content: "";
          width: 1px;
          height: 14px;
          background-color: #000;
          margin: 0 6px;
        }
      }
    }
  }
}

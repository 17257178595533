$btnImmo-icon-width: 73px;
$btnImmo-icon-height: 89px;

.btnImmo {
  @extend %flexbox;
  @include flex-direction(column);
  @include justify-content(center);
  @include align-items(center);
  border: 1px solid #b2b2b2;
  color: #262626;
  padding: 14px 18px 24px;
  span {
    text-align: center;
    margin-bottom: 24px;
    width: 100%;
  }
  i {
    width: $btnImmo-icon-width;
    height: $btnImmo-icon-height;
    background-image: url("../images/sprite-immo.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      background-image: url("../images/sprite-immo@2x.png");
    }
  }
  &.add i { background-position: #{$btnImmo-icon-width * 0} #{$btnImmo-icon-height * 0};}
  &.remove i { background-position: #{$btnImmo-icon-width * -1} #{$btnImmo-icon-height * 0};}
  &.edit i { background-position: #{$btnImmo-icon-width * -2} #{$btnImmo-icon-height * 0};}

  &:hover {
    i {
      background-image: url("../images/sprite-immo-hover.png");
    }
    &.add i { background-position: #{$btnImmo-icon-width * 0} #{$btnImmo-icon-height * 0};}
    &.remove i { background-position: #{$btnImmo-icon-width * -1} #{$btnImmo-icon-height * 0};}
    &.edit i { background-position: #{$btnImmo-icon-width * -2} #{$btnImmo-icon-height * 0};}
  }

  &:hover {
    text-decoration: none;
    background-color: #247f6b;
    color: #fff;
  }
}

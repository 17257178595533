.field {
  @extend %flexbox;
  @include flex-direction(column);
  @include flex-wrap(wrap);
  margin-bottom: $mobile-question-margin;
  @include media-breakpoint-up(sm) {
    margin-bottom: $question-margin;
  }
  label {
    @extend %flexbox;
    @include align-items(center);
  }
  .question-wrapper {
    .col:last-child {
      .question-wrapper {
        margin-bottom: 0;
      }
    }
    .question-wrapper {
      margin-bottom: $mobile-question-margin;
      @include media-breakpoint-up(sm) {
        margin-bottom: $question-margin;
      }
    }
  }
  .field {
    @extend %flexbox;
    @include flex-direction(column);
    position: relative;
    margin-bottom: 0;
    @if $labelPosition == 'fixed' {
      input {
        padding: 1.4rem 0.75rem .3rem;
        &:focus, &.filled {
          ~ label {
            font-size: 11px;
            top: 8px;
          }
        }
        + label {
          pointer-events: none;
          position: absolute;
          top: 16px;
          left: 1.75rem;
          transition: all .2s;
        }
      }
    } @else {
      label {
        order: -1;
      }
    }
    &.inline {
      margin-bottom: 30px;
      label {
        padding: 0;
        font-size: 16px;
        color: $gray-900;
        font-weight: normal;
      }
      .question-wrapper {
        margin: 0;
      }
      &.radio {
        .field {
          padding: 0;
          .btn {
            background-color: $input-bg;
            border: $input-border-width solid $input-border-color;
            margin-left: -1px;
            padding: $input-padding-y $input-padding-x;
            @include font-size($input-font-size);
            &:focus {
              box-shadow: none;
            }
            &.selected {
              color: #fff;
              background-color: $quaternary;
              border-color: $quaternary;
            }
          }
          &:first-child {
            .btn {
              border-radius: $border-radius 0 0 $border-radius;
              margin-left: 0px;
            }
          }
          &:last-child {
            .btn {
              border-radius: 0 $border-radius $border-radius 0;
            }
          }
        }
      }
    }
  }
  &.noPadding {
    .question-wrapper {
      padding: 0;
    }
  }
  &.inline {
    @include flex-direction(row);
    @include align-items(center);

    // Question
    .question-wrapper {
      @include media-breakpoint-up(md) {
        padding: 0 100px 0 30px;
        @include flex(1 0 auto);
      }

      [class*="grid-"] {
        @include justify-content(flex-start);
      }
    }
    .question-title {
      @include media-breakpoint-up(md) {
        @include flex(none);
        margin-bottom: 0;
      }
    }

    // Subquestion
    label, .form-control {
      @include flex(1 0 auto);
      margin-bottom: 0;
    }
    label {
      @include media-breakpoint-up(md) {
        @include flex(0 0 auto);
      }
      padding-right: 14px;
      white-space: nowrap;
    }
    .form-control {
      @include flex(1);
    }
    &.small-label {
      label {
        font-size: 14px;
      }
    }
  }
  &.suffixed {
    label {
      order: 1;
      padding-right: 0;
      padding-left: 14px;
    }
  }
  .question-wrapper {
    @include flex(1 1 auto);
  }
  > .question-wrapper {
    padding: 0 10px;
  }
  &.checkbox {
    @extend %flexbox;
    @include flex-wrap(wrap);
  }
}

.column {
  .question-title {
    @include flex(none);
  }
}



.field-backgrounded {
  background-color: #f5f4f0;
  padding: 40px 80px 60px;
  > .question-title {
    color: $quaternary;
  }
}


@for $j from 1 through 1000 {
  .field.form-control-width-#{$j} {
    .form-control {
      @include flex(0 0 (($j) * 1px));
    }
  }
}

.questions-inline.field {
  margin-top: 40px;
  &.signature {
    > .question-wrapper {
      > .row {
        > .col:first-child {
          padding-right: 0;
        }
        > .col:nth-child(2) {
          padding-left: 6px;
        }
      }
    }
  }
  .question-wrapper, .question-wrapper .question-wrapper {
    margin-bottom: 0;
  }
  .field {
    margin-bottom: 14px;
    label {
      margin-right: 10px;
    }
  }
}

.question-title {
  @include flex(1 0 auto);
  @include justify-content($mobile-question-title-alignment);
  text-align: $mobile-question-title-alignment;
  z-index: 1;
  color: #3c8d7b;
  font-size: $mobile-question-title-size;
  font-weight: $mobile-question-title-weight;
  margin: $mobile-question-title-margin;
  padding: $mobile-question-title-padding;
  @include media-breakpoint-down(sm) {
    position: sticky;
    top: 0;
    @include gradient-orientation(vertical, #fff, rgba(#fff, 0), 60%, 100%);
  }
  @include media-breakpoint-up(md) {
    margin: $question-title-margin;
  }
}
